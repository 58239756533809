<template>
    <div class="app-container activity-detail-tabs">
        <div class="contant-top">
            <div class="left">
                <p>活动报名</p>
                <ul>
                    <li>
                        <p>活动标题</p>
                        <p>{{ activeList.activityTitle }}</p>
                    </li>
                    <li>
                        <p>允许报名时间</p>
                        <p>{{ activeList.enrollStartTime | dateFormat(("YYYY-MM-DD HH:mm:ss")) }}</p>
                    </li>
                    <li>
                        <p>活动开始时间</p>
                        <p>{{ activeList.startTime | dateFormat(("YYYY-MM-DD HH:mm:ss")) }}</p>
                    </li>
                    <li v-if="activeList.imageId !== null">
                        <p>小程序报单页二维码</p>
                        <p style="margin-bottom: 50px;">
                            <img :src="imageUrl" alt="" style="width: 80px;">
                            <span class="el-icon-download" style="color: #1A4CEC; cursor: pointer; font-size: 14px;" @click="downloadHandler">下载二维码</span>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="right">
                <p class="el-icon-edit-outline" style="color: #1A4CEC; font-size: 16px; cursor: pointer;"></p>
                <span style="color: #1A4CEC; font-size: 14px; cursor: pointer;" @click="editHandler">    编辑</span>
                <ul>
                    <li>
                        <p>活动地点</p>
                        <p>{{ activeList.activityPlace }}</p>
                    </li>
                    <li>
                        <p>允许报名结束时间</p>
                        <p>{{ activeList.enrollEndTime | dateFormat(("YYYY-MM-DD HH:mm:ss")) }}</p>
                    </li>
                    <li>
                        <p>活动结束时间</p>
                        <p>{{ activeList.endTime | dateFormat(("YYYY-MM-DD HH:mm:ss")) }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="contant-under">
            <div class="top">
                <p>报名情况</p>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
            </div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler"></finalTable>
        </div>
        <div>
            <el-dialog :visible.sync="activityObj.dialogForm" width="728px" :before-close="handleClose"
                :close-on-click-modal="false">
                <template slot="title">
                    <span>{{ activityObj.dialogTitle }}</span>
                </template>
                <evenDetail v-if="activityObj.dialogForm" :dialogStatus="activityObj.dialogStatus" :id="activityObj.id"
                    @close="handleClose"></evenDetail>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import { dateFormat } from "@/filters/index";
import { envInfo } from "@/constants/envInfo";
import evenDetail from "./evenDetail";
import {
    getActivityDetails,
    applicationListExport,
    getApplicationDetails
} from "@/api/ruge/gsPark/customerService/eventregistration.js";
export default {
    name: "activityDetailTabs",
    components: {
        finalTable,
        evenDetail
    },
    data() {
        return {
            activeList: '',
            imageUrl: '',
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "name", label: "报名人姓名", width: "" },
                    { prop: "phone", label: "联系电话", width: "240" },
                    { prop: "companyName", label: "公司名称", width: "" },
                    { prop: "remark", label: "备注", width: "" },
                    { prop: "createTime", label: "报名时间", width: "" },
                    // { prop: "operation", label: "操作", width: "200" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    name: {
                        type: "input",
                        label: "报名人姓名",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        prefixIcon: "el-icon-search",
                    },
                    phone: {
                        type: "numberInput",
                        label: "联系电话",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    companyName: {
                        type: "input",
                        label: "公司名称",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        prefixIcon: "el-icon-search",
                    },
                    remark: {
                        type: "input",
                        label: "备注",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入",
                        prefixIcon: "el-icon-search",
                    },
                    // createTime: {
                    //     type: "input",
                    //     label: "报名时间",
                    //     value: "",
                    //     actionType: "goSearch",
                    //     placeholder: "请输入",
                    //     prefixIcon: "el-icon-search",
                    // }
                    // operation: {
                    //     type: "button",
                    //     filterCount: "",
                    //     actionType: "clickEvent",
                    //     eventName: "showMoreFilters",
                    //     label: "更多筛选",
                    // },
                },
                // 表格内容配置
                detailConfig: {
                    createTime: {
                        type: "dateFormat",
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            activityObj: {
                tableLoading: false,
                dialogVisible: false,
                dialogForm: false,
                typeFlag: null,
                dialogStatus: "edit",
                dialogTitle: '新建报名',
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    id: '',
                    activityTitle: null,
                    activityPlace: null,
                    enrollStartTime: null,
                    enrollEndTime: null,
                    startTime: null,
                    endTime: null,
                    enrollmentStatus: null,
                    enrollmentCount: null,
                    name:null,
                    phone:null,
                    companyName:null,
                    remark:null,
                    name:null,
                    name:null,
                    enrollmentId:null,
                }
            },
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            // console.log(this.$route,'222222222222');
            this.activityObj.listQuery.id = this.$route.query.dataId
            this.activityObj.listQuery.enrollmentId = this.$route.query.dataId
            this.activityObj.tableLoading = true;
            const params = { ...this.activityObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getActivityDetails(params).then((res) => {
                this.activeList = res.data
                this.imageUrl = envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + res.data.imageId
                // 设置页面详情标题名称 -XXX
                this.$eventBus.$emit("setDetailAlias", res.data.activityTitle);
            }).finally(() => {
                this.activityObj.tableLoading = false;
            });

            getApplicationDetails(params).then((res) => {
                this.dataset.pageVO.total = res.data.total;
                this.dataset.tableData = res.data.rows;
            }).finally(() => {
                this.activityObj.tableLoading = false;
            });
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                // 更新数据
                this.activityObj.listQuery = {
                    ...this.activityObj.listQuery,
                    ...datas.params,
                };
                this.activityObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                // 配置分页
                this.activityObj.listQuery.current = datas.params.current.page;
                this.activityObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            }
        },
        // 下载二维码
        downloadHandler() {
            let imageId = this.activeList.imageId
            //下载文件
            const url =
                envInfo.bgApp.archivePath +
                "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
                imageId;
            const asemName = "jpg";
            const element = document.createElement("a");
            element.setAttribute("href", url);
            // element.setAttribute("download", asemName);
            element.setAttribute("download", `image.${asemName}`);
            element.style.display = "none";
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        // 导出
        exportHandler() {
            applicationListExport(this.activityObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "活动报名情况",
                    taskStatus: 0,
                    rootPath: "customerGSPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
        // 编辑
        editHandler() {
            this.activityObj.dialogTitle = '活动编辑'
            this.activityObj.id = this.activityObj.listQuery.id;
            this.activityObj.dialogStatus = "edit";
            this.activityObj.dialogForm = true;
        },
        handleClose(freshFlag) {
            this.activityObj.dialogForm = false
            freshFlag && this.getTableList();
        },
    },
    watch: {
        activityObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>
<style lang="less" scoped>
.activity-detail-tabs {
    .contant-top {
        display: flex;
        padding: 2rem;
        background-color: #f8f8f8;
        border-radius: 4px;

        .left {
            width: 30%;

            p:first-child {
                font-size: 16px;
                font-weight: 600;
            }

            li {
                margin-top: 1.5rem;

                p:first-child {
                    color: #6B7278;
                    font-size: .75rem;
                    height: 22px;
                    line-height: 22px;
                }

                p:last-child {
                    margin-top: .25rem;
                    color: #2F3941;
                    font-size: .875rem;
                    font-weight: 700;
                    height: 24px;
                    line-height: 24px;
                }
            }
        }

        .right {
            width: 70%;

            p:first-child {
                font-size: 16px;
                font-weight: 600;
            }

            li {
                margin-top: 1.5rem;

                p:first-child {
                    color: #6B7278;
                    font-size: .75rem;
                    height: 22px;
                    line-height: 22px;
                }

                p:last-child {
                    margin-top: .25rem;
                    color: #2F3941;
                    font-size: .875rem;
                    font-weight: 700;
                    height: 24px;
                    line-height: 24px;
                }
            }
        }
    }

    .contant-under {
        margin-top: 32px;
        padding: 2rem;
        // border: 1px solid #f8f8f8 ;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
        border-radius: 4px;

        .top {
            display: flex;
            justify-content: space-between;
            font-weight: 600;

        }
    }
}</style>